// hooks/index.ts
import 'dotenv/config';
import { ethers, utils } from "ethers";
//If we get a type error on the contract argument in useContractFunction, its likely an ethers version mismatch
import { Contract } from '@usedapp/core/node_modules/@ethersproject/contracts'

// These are broken, use current fix replacements
import { useContractCall, useContractFunction } from "@usedapp/core";
// Here are the replacements
import { useContractFunction__fix } from './useContractFunction';
import { useSendTransaction__fix } from './useSendTransaction';

import contractAbi from "../contracts/abi/Chungus.json";
import { contractAddress } from "../contracts"

const contractInterface = new ethers.utils.Interface(contractAbi);
const contract = new Contract(contractAddress, contractInterface);

export function useContractMethod(methodName: string) {
    const { state, send } = useContractFunction__fix(contract, methodName, { transactionName: 'Mint', });
    return { state, send };
  }
  

  
  