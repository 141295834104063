import { extendTheme, ThemeConfig } from "@chakra-ui/react";

const config : ThemeConfig = {
  initialColorMode: "dark",
  useSystemColorMode: false,
}

export default extendTheme({
  config,
  fonts: {
    heading: "Inter",
    body: "Inter",
  },
});
